import React from "react";
import { Link } from "gatsby";

import "./index.scss";

const BtnContact = ({ children }) => {
	return (
		<Link to="/#contact" id="btn-contact">
			<button className="btn cta">
				<span>{children}</span>
			</button>
		</Link>
	);
};

export default BtnContact;
