import React from "react";
import { Link } from "gatsby";

import "./index.scss";
import BtnContact from "../btnContact";
import Logo from "../../images/logo-cli.svg";

const Footer = () => {
	return (
		<footer id="footer">
			<ul id="footer-list">
				<li>
					<div className="footer-about">
						<div className="footer-about-header">
							<img
								src={Logo}
								alt="Logo de l'association"
								className="footer-logo"
								height="40px"
								width="40px"
							/>
							<h4>CLI</h4>
						</div>
						<p>
							La CLI est une association à but non lucratif spécialisée dans le
							numérique fournissant une large variété de prestations numériques.
						</p>
					</div>
				</li>
				<li>
					<div className="footer-contact">
						<BtnContact>Nous contacter</BtnContact>
						<p>06 95 75 94 63</p>
					</div>
					<div className="footer-legal">
						<Link to="/statuts">Nos statuts </Link>
						<Link to="/mentions-legales">Mentions légales</Link>
					</div>
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
