import React, { useState } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";

import HeaderContext from "../../context/headerContext";

import Navbar from "../navbar";
import Footer from "../footer";

toast.configure();

const Layout = ({ children }) => {
	const [headerState, setHeaderState] = useState(false);

	return (
		<>
			<HeaderContext.Provider
				value={{
					isHeaderHiddenFct: (x) => {
						setHeaderState(x);
					},
					isHeaderHidden: headerState
				}}>
				<Navbar />
				<main>{children}</main>
			</HeaderContext.Provider>
			<ToastContainer
				position="bottom-center"
				autoClose={3000}
				newestOnTop
				hideProgressBar
				closeOnClick
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
