import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";

import HeaderContext from "../../context/headerContext";
import "./index.scss";
import MenuIcon from "../../images/menu.svg";

const Navbar = () => {
	const headerContext = useContext(HeaderContext);
	const [toggleMenu, setToggleMenu] = useState(false);
	const [underline, setUnderline] = useState("");

	const handleShowNavbar = (context) => {
		if (context.isHeaderHidden === true && !toggleMenu) {
			return { position: "absolute", top: "0px" };
		} else {
			return { background: "white", position: "fixed", top: "0px" };
		}
	};

	const handleMenu = () => {
		setToggleMenu(!toggleMenu);
	};

	const checkWinLocation = () => {
		if (window.location.pathname === "/") return;
		const pathname = window.location.pathname;
		return pathname;
	};

	const handleUnderline = () => {
		setUnderline(checkWinLocation());
	};

	useEffect(() => {
		if (!checkWinLocation()) return;
		handleUnderline();
	}, []);

	return (
		<div id="navbar-container">
			<nav style={handleShowNavbar(headerContext)} className={toggleMenu ? "nav-o" : "nav-c"}>
				<div className={toggleMenu ? "details-open" : "details"}>
					<Link to="/services" className={toggleMenu ? "menu-item" : "menu-item-hidden"}>
						<span className={underline === "/services" ? "menu-item-underlined" : ""}>
							Services
						</span>
					</Link>
					<Link to="/a-propos" className={toggleMenu ? "menu-item" : "menu-item-hidden"}>
						<span className={underline === "/a-propos" ? "menu-item-underlined" : ""}>
							L'Association
						</span>
					</Link>
				</div>
				<div className="title-and-menu">
					<Link id="navbar-title" to="/">
						<span>CLI</span>
					</Link>
					<button className="menu-toggle-container" onClick={handleMenu}>
						<img
							src={MenuIcon}
							className="menu-toggle"
							alt="Menu icon"
							height="30px"
							width="30px"
						/>
					</button>
				</div>
				<Link to="/#contact" id="contact-btn" className="menu-btn">
					<button className="btn">Nous contacter</button>
				</Link>
			</nav>
		</div>
	);
};

export default Navbar;
